import Navbar from "./components/navbar/Navbar";
import Hero from "./components/sections/Hero";
import Advantages from "./components/sections/Advantages";
import AboutUs from "./components/sections/AboutUs";
import Contact from "./components/sections/Contact";
import Colorways from "./components/sections/Colorways";
import CollectionElement from "./components/collection/CollectionElement";

import { urlFor, client } from "./client";
import { useState, useEffect } from "react";
import SaunaElement from "./components/sauna/SaunaElement";

function App() {
  const [heroData, setHeroData] = useState([]);
  const [advantagesData, setAdvantagesData] = useState([]);
  const [collectionData, setCollectionData] = useState([]);
  const [colorwaysData, setColorwaysData] = useState([]);
  const [aboutUsData, setAboutUsData] = useState([]);
  const [contactData, setContactData] = useState([]);
  const [saunaData, setSaunaData] = useState([]);

  useEffect(() => {
    client.fetch('*[_type == "Hero"]').then((data) => {
      setHeroData(data);
    });

    client.fetch('*[_type == "Advantages"]').then((data) => {
      setAdvantagesData(data);
    });

    client.fetch('*[_type == "Collection"]').then((data) => {
      setCollectionData(data);
    });

    client.fetch('*[_type == "Colorway"]').then((data) => {
      setColorwaysData(data);
    });

    client.fetch('*[_type == "aboutUs"]').then((data) => {
      setAboutUsData(data);
    });

    client.fetch('*[_type == "Contact"]').then((data) => {
      setContactData(data);
    });

    client.fetch('*[_type == "Saunas"]').then((data) => {
      setSaunaData(data);
    });

    document.location = "#strona-glowna";
  }, []);

  return (
    <div className="w-full h-full">
      <Navbar />
      <div className="snap-y snap-mandatory h-screen w-full overflow-x-hidden">
        {heroData.map((data, i) => (
          <Hero heroData={data} key={i} />
        ))}

        {advantagesData.map((data, i) => (
          <Advantages
            text={data.list}
            img={urlFor(data.image)}
            anim={data.anim}
            key={i}
            title={data.title}
          />
        ))}
        {collectionData
          .sort((a, b) => {
            return a.order - b.order;
          })
          .map((data, i) => (
            <CollectionElement
              key={i}
              name={data.name}
              text={data.list}
              position={i}
              img={urlFor(data.image)}
              anim={data.anim}
            />
          ))}

        {colorwaysData.map((data, i) => (
          <Colorways
            cabin={data.cabin}
            wardrobe={data.wardrobe}
            info={data.info}
            img={urlFor(data.image)}
            key={i}
          />
        ))}

        {saunaData.map((data, i) => (
          <SaunaElement
            key={i}
            name={data.name}
            text={data.list}
            position={i}
            img={urlFor(data.image)}
          />
        ))}

        {aboutUsData.map((data, i) => (
          <AboutUs text={data.text} key={i} />
        ))}

        {contactData.map((data, i) => (
          <Contact
            text={data.text}
            details={data.details}
            phone={data.phone}
            email={data.email}
            file={data.form}
            ig={data.instagramLink}
            linkedin={data.linkedinLink}
            key={i}
          />
        ))}
      </div>
    </div>
  );
}

export default App;
