import { urlFor } from "../../client";

function Hero({ heroData }) {
    return (
        <section
            className="w-full h-screen pt-16 bg-no-repeat bg-cover bg-center flex justify-center items-center text-white flex-col space-y-4 font-Montserrat snap-center"
            id="strona-glowna"
            style={{ backgroundImage: `url(${urlFor(heroData.image)})` }}
        >
            <label
                className={`text-4xl md:text-6xl font-Alcubierre tracking-widest border border-white border-1 p-2 text-center text-white`}
            >
                PRESTIKA
            </label>
            <p>{heroData.text}</p>
        </section>
    );
}

export default Hero;
