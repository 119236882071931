import ListElement from "../ListElement";
import Modal from "../Modal";
import Button from "../button/Button";

function CollectionElement({ name, text, position, img, anim }) {
  const leftSideImage =
    "flex flex-col w-full h-full justify-center itemsś-center md:pr-8 md:flex-row-reverse";
  const rightSideImage =
    "flex flex-col w-full h-full justify-center items-center md:pl-8 md:flex-row";

  return (
    <div
      className="w-full h-screen md:pt-16 pb-12 md:pb-0 pt-32 px-4 md:px-0 font-Montserrat flex flex-col justify-center items-center -z-10 snap-start"
      id={"kolekcje"}
    >
      <div className={position % 2 === 0 ? rightSideImage : leftSideImage}>
        <div className="w-full h-full">
          <label className="text-3xl py-8 md:hidden">Kolekcja {name}</label>
          <div className="hidden md:flex flex-col items-center justify-center text-center w-full h-full space-y-12">
            <label className="text-3xl text-left">Kolekcja {name}</label>
            <ul className="list-disc flex flex-col space-y-3 text-center text-sm px-6">
              {text.map((t, i) => (
                <ListElement key={i} color="black" text={t} />
              ))}
            </ul>
            {anim && <Button className="hidden md:block" anim={anim} />}
          </div>
        </div>

        <div
          className={`h-full w-full md:m${
            position % 2 === 0 ? "l" : "r"
          }-4 bg-center bg-cover bg-no-repeat rounded-lg relative md:rounded-none md:rounded-${
            position % 2 === 0 ? "l" : "r"
          }-lg`}
          style={{ backgroundImage: `url(${img})` }}
        >
          <Modal anim={anim}>
            {" "}
            <ul className="list-disc flex flex-col space-y-3 text-center text-sm px-6 overflow-scroll w-full h-full py-4">
              {text.map((t, i) => (
                <ListElement key={i} color="white" text={t} />
              ))}
            </ul>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default CollectionElement;
