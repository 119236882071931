import ListElement from "../ListElement";
import { PortableText } from "@portabletext/react";
import Slider from "../Slider";

function Colorways({ cabin, wardrobe, info, img }) {
    return (
        <div
            className="w-full h-screen pt-16 pb-4 md:pb-0 px-2 mb:px-0 font-Montserrat flex flex-col justify-center items-center md:p-2 md:pr-0 snap-start"
            id="wykonczenie"
        >
            <div className="flex flex-col w-full h-full justify-center items-center md:pt-16 md:pl-8 md:flex-row">
                <label className="text-xl md:text-3xl py-8 md:hidden">
                    Opcje wykończenia
                </label>
                <div className="hidden md:flex flex-col items-center justify-center text-center h-full space-y-8 mx-8">
                    <label className="text-2xl text-left">
                        Opcje wykończenia
                    </label>
                    <ul className="list-disc flex flex-col space-y-2 text-center text-sm">
                        <label className="text-normal">Szafa:</label>
                        {wardrobe.map((t, i) => (
                            <ListElement key={i} color="black" text={t} />
                        ))}
                        <label className="text-normal">Kabina:</label>
                        {cabin.map((t, i) => (
                            <ListElement key={i} color="black" text={t} />
                        ))}
                    </ul>
                    <div>
                        <PortableText value={info} />
                    </div>
                </div>
                <div
                    className={`relative h-full w-full bg-right text-white bg-cover bg-no-repeat rounded-lg md:rounded-none md:rounded-l-lg z-0 overflow-hidden`}
                    style={{ backgroundImage: `url(${img})` }}
                >
                    <Slider>
                        <div className="absolute w-full h-full bg-black opacity-70 -z-10 md:hidden"></div>{" "}
                        <ul className="flex flex-col space-y-3 w-full h-full text-sm md:hidden p-4 overflow-scroll">
                            <label className="text-xl w-full text-center">
                                Szafa:
                            </label>
                            {wardrobe.map((t, i) => (
                                <ListElement key={i} color="white" text={t} />
                            ))}
                            <label className="text-xl w-full text-center">
                                Kabina:
                            </label>
                            {cabin.map((t, i) => (
                                <ListElement key={i} color="white" text={t} />
                            ))}
                            <div className="w-full text-center">
                                <PortableText value={info} />
                            </div>
                        </ul>
                    </Slider>
                </div>
            </div>
        </div>
    );
}

export default Colorways;
