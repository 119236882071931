import React from "react";
import { FiPlayCircle } from "react-icons/fi";

function Button(anim) {
  return (
    <a
      href={anim.anim}
      // target="_blank"
      className="w-fit bg-black rounded-md hover:scale-110 duration-100"
    >
      <div className="p-3 flex space-x-4 items-center justify-around">
        <FiPlayCircle className="text-white h-8 w-8" />
        <button className="text-white">Zobacz Animację</button>
      </div>
    </a>
  );
}

export default Button;
