import sanityClient from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

export const client = sanityClient({
    projectId: "sf0hg2wy",
    dataset: "production",
    apiVersion: "2022-10-29",
    useCdn: true,
    token: "skA0SyZL88K6VcfMUOZkiAzVJILpukqDC5L2PnJ08kx9sIDhh1jPSCtVMfb77eK1oGW6dksHZ4N4IyHEIFdNFIsWZu7ostu5DnublwZKY8XMd3MJXeN47uZbPTMv9bOJZaux9TbiLABkW0Jd7WNwwCzcobainSX3rISXBAvSqfiO1qLiXh05",
    ignoreBrowserTokenWarning: true,
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);
