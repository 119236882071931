import { FaInstagram, FaLinkedin } from "react-icons/fa";

function Contact({ text, email, phone, file, details, ig, linkedin }) {
  //console.log(file);
  const getUrlFromId = (ref) => {
    const [_file, id, extension] = ref.split("-");
    return `https://cdn.sanity.io/files/sf0hg2wy/production/${id}.${extension}`;
  };

  return (
    <div
      className="w-full h-screen pt-16 font-Montserrat flex flex-col justify-center items-center p-2 overflow-hidden snap-start relative"
      id="kontakt"
    >
      <label className="text-2xl md:text-4xl font-bold w-full text-left pl-8">
        Kontakt
      </label>
      <div className="w-full h-full bg-black rounded-lg md:grid md:grid-cols-2 flex flex-col justify-center items-center md:flex-row space-y-4 md:space-x-16">
        <div className="flex justify-center items-center h-full w-full">
          <p className=" text-white text-center p-8 text-sm md:text-2xl">
            {text}
          </p>
        </div>

        <div className="flex flex-col items-center justify-center text-white h-full w-full md:text-2xl">
          <p className="text-white p-8 text-sm md:text-2xl whitespace-pre-wrap text-left leading-normal">
            {details}
          </p>
          {/* <span>E-mail: {email}</span>
          <span>Tel. {phone}</span> */}
          <a href={`${getUrlFromId(file.asset._ref)}?dl=`}>
            <button className="text-black p-2 bg-white rounded-lg mt-8">
              Pobierz formularz
            </button>
          </a>
        </div>

        <div className="absolute left-4 md:-left-8 bottom-4 text-white flex items-center gap-x-4 z-10">
          {/* <p className="">Polityka prywatności</p> */}
          <a href={linkedin}>
            <FaLinkedin className="w-6 h-6" />
          </a>
          <a href={ig}>
            <FaInstagram className="w-6 h-6" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Contact;
