import { PortableText } from "@portabletext/react";

function ListElement({ text, color }) {
    return (
        <div className="flex space-x-6 text-left justify-center items-center">
            <div className={`w-2 h-2 rounded-full bg-${color}`} />
            <div className={`w-full text-${color} font-Montserrat`}>
                <PortableText value={text} />
            </div>
        </div>
    );
}

export default ListElement;
