import { useState } from "react";
import { BsChevronDoubleDown } from "react-icons/bs";
import { RxCross1 } from "react-icons/rx";
import { FiPlayCircle } from "react-icons/fi";

function Slider({ children, anim }) {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="w-full h-full transition-all duration-500 ease-in-out overflow-hidden md:hidden">
      <div
        className={`w-full h-full bg-black rounded-lg opacity-80 translate-y-[100%] transition-all duration-300 ease-in-out flex items-center ${
          isOpen && "translate-y-[0%]"
        }`}
      >
        {children}
      </div>
      <RxCross1
        className={`absolute top-5 right-5 ${!isOpen && "hidden"} text-white`}
        onClick={onClick}
      />
      <div
        className="absolute left-[50%] bottom-4 hover:cursor-pointer z-10"
        onClick={onClick}
      >
        <div className="w-12 h-12 flex rounded-full space-x-1 justify-center items-center hover:scale-110 duration-100 relative -left-[50%]">
          <BsChevronDoubleDown
            className={`h-6 w-6 animate-bounce text-white ${
              isOpen && "hidden"
            }`}
          />
        </div>
      </div>
      {anim && (
        <div className="absolute right-4 bottom-4 hover:cursor-pointer z-10 space-y-2">
          <a
            href={anim}
            target="_blank"
            className="w-12 h-12 flex bg-white rounded-full space-x-1 justify-center items-center hover:scale-110 duration-100"
          >
            <FiPlayCircle className="w-8 h-8" />
          </a>
        </div>
      )}
    </div>
  );
}

export default Slider;
