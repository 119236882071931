import React from "react";
import ListElement from "../ListElement";
import Slider from "../Slider";
import Button from "../button/Button";

function Advantages({ text, img, title, anim }) {
  // console.log(anim);
  return (
    <div
      className="w-full h-screen pt-16 font-Montserrat flex flex-col justify-center items-center p-4 md:pr-0 snap-start"
      id="co-zyskujemy"
    >
      <div className="flex flex-col w-full h-full justify-center items-center md:pl-8 md:flex-row">
        <label className="text-xl md:text-3xl py-8 md:hidden">{title}</label>
        <div className="hidden md:flex flex-col items-center justify-center text-center h-full space-y-12 md:pr-4">
          <label className="text-4xl text-left">{title}</label>
          <ul className="list-disc flex flex-col space-y-3 text-center text-sm">
            {text.map((t, i) => (
              <ListElement key={i} color="black" text={t} />
            ))}
          </ul>
          <Button className="hidden md:block" anim={anim} />
        </div>

        <div
          className={`relative h-full w-full bg-right bg-cover bg-no-repeat rounded-lg md:rounded-none md:rounded-l-lg z-0 overflow-hidden`}
          style={{ backgroundImage: `url(${img})` }}
        >
          <Slider anim={anim}>
            <ul className="list-disc flex flex-col space-y-3 text-center text-sm px-6 overflow-scroll w-full h-full py-4">
              {text.map((t, i) => (
                <ListElement key={i} color="white" text={t} />
              ))}
            </ul>
          </Slider>
        </div>
        {/* <div className="relative h-full w-full rounded-lg">
                    <video>
                        <source src={anim} type="video/webm" />
                    </video>
                </div> */}
      </div>
    </div>
  );
}

export default Advantages;
