import React from "react";

function AboutUs({ text }) {
    return (
        <div
            className="w-full h-screen py-16 font-Montserrat flex flex-col justify-center items-center snap-start"
            id="o-nas"
        >
            <label
                className={`text-2xl md:text-6xl font-Alcubierre tracking-widest border border-black border-1 p-2 text-center text-black`}
            >
                PRESTIKA
            </label>
            <p className="text-center m-8 md:text-2xl md:px-64">{text}</p>
        </div>
    );
}

export default AboutUs;
