function Hamburger({ isOpen, navToggle }) {
    const onClick = () => {
        navToggle(!isOpen);
    };

    //const middleBar = ("-translate-x-12 bg-transparent transition-all duration-500 ease-in-out");
    //const topBar = "rotate-45 translate-y-[0.75rem]";
    //const botBar = "-rotate-45 -translate-y-[0.75rem]";
    const middleBar = "";
    const botBar = "";
    const topBar = "";

    return (
        <>
            <div
                className="h-12 w-12 relative flex justify-center items-center hover:cursor-pointer transition-all duration-500 ease-in-out"
                onClick={onClick}
            >
                <div
                    className={`absolute w-8 h-1 bg-black rounded-lg translate-y-3 transition-all duration-500 ease-in-out ${
                        isOpen && topBar
                    }`}
                ></div>
                <div
                    className={`absolute w-8 h-1 bg-black rounded-lg ${
                        isOpen && middleBar
                    }`}
                ></div>
                <div
                    className={`absolute w-8 h-1 bg-black rounded-lg -translate-y-3 transition-all duration-500 ease-in-out ${
                        isOpen && botBar
                    }`}
                ></div>
            </div>
        </>
    );
}

export default Hamburger;
