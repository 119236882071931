import Hamburger from "./Hamburger";
import { useState } from "react";

function Navbar() {
    const [isOpen, setIsOpen] = useState(false);
    const [isTop, setIsTop] = useState(false);

    return (
        <div id="nav">
            <nav
                className={`h-16 w-full bg-${
                    isTop ? "transparent" : "white"
                } flex justify-between px-8 items-center fixed z-30`}
            >
                <label
                    className={`font-Alcubierre tracking-widest border border-${
                        isTop ? "white" : "black"
                    } border-1 p-2 text-center text-${
                        isTop ? "white" : "black"
                    }`}
                >
                    PRESTIKA
                </label>
                <Hamburger navToggle={setIsOpen} isOpen={isOpen} />
            </nav>

            <div
                className={` h-full w-full fixed bg-white transition-all duration-200 ease-in-out ${
                    isOpen ? "translate-x-[0%] z-20 " : "translate-x-[200%]"
                }`}
            >
                <ul className="flex flex-col h-full justify-center items-center space-y-3 font-Montserrat">
                    <li onClick={() => setIsOpen(!isOpen)}>
                        <a href="#strona-glowna">Strona startowa</a>
                    </li>
                    <li onClick={() => setIsOpen(!isOpen)}>
                        <a href="#co-zyskujemy">Co zyskujemy?</a>
                    </li>
                    <li onClick={() => setIsOpen(!isOpen)}>
                        <a href="#kolekcje">Kolekcje</a>
                    </li>
                    <li onClick={() => setIsOpen(!isOpen)}>
                        <a href="#wykonczenie">Opcje wykończenia</a>
                    </li>
                    <li onClick={() => setIsOpen(!isOpen)}>
                        <a href="#o-nas">O nas</a>
                    </li>
                    <li onClick={() => setIsOpen(!isOpen)}>
                        <a href="#kontakt">Kontakt</a>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Navbar;
