import { useState } from "react";
import { FiPlayCircle } from "react-icons/fi";

function Modal({ children, anim }) {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="w-full h-full transition-all duration-500 ease-in-out overflow-hidden md:hidden">
      <div
        className={`w-full h-full bg-black rounded-lg opacity-80 translate-y-[100%] transition-all duration-300 ease-in-out flex items-center ${
          isOpen && "translate-y-[0%]"
        }`}
      >
        {children}
      </div>
      <div className="absolute right-4 bottom-4 hover:cursor-pointer z-10 space-y-2">
        <div
          className="w-12 h-12 flex bg-white rounded-full space-x-1 justify-center items-center hover:scale-110 duration-100"
          onClick={onClick}
        >
          <div className="h-2 w-2 bg-black rounded-full"></div>
          <div className="h-2 w-2 bg-black rounded-full"></div>
          <div className="h-2 w-2 bg-black rounded-full"></div>
        </div>
        {anim && (
          <a
            href={anim}
            target="_blank"
            className="w-12 h-12 flex bg-white rounded-full space-x-1 justify-center items-center hover:scale-110 duration-100"
          >
            <FiPlayCircle className="w-8 h-8" />
          </a>
        )}
      </div>
    </div>
  );
}

export default Modal;
